import React, { useState, useEffect } from 'react';
import '../styles/StateDevelopmentComponent.css'; // Import CSS for styling

const StateDevelopmentComponent = () => {
  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_HOST}:${process.env.REACT_APP_BACKEND_API_PORT}/stats`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log(data)
        setStatsData(data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };
    fetchData();

  }, []);

  // Calculate the sum of total_pnl values
  const totalPnlSum = statsData.reduce((acc, item) => acc + item.total_pnl, 0);

  // Get the current date and time
  const currentDateTime = new Date().toLocaleString();

  return (
    <div>
      <h2>Stats</h2>
      <div className="stats-summary">
        <h2>Stats</h2>
        <div className="stats-info">
          <p> {currentDateTime}</p>
          <p className={totalPnlSum >= 0 ? 'positive' : 'negative'}>Net: {totalPnlSum}</p>
        </div>
      </div>
      <table id="stats-table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Type</th>
            <th>Duration</th>
            <th>Unclosed PnL</th>
            <th>Grid PnL</th>
            <th>Total PnL</th>
            <th>Updated</th>
            {/* <th>Action</th> Uncomment this if action field is included */}
          </tr>
        </thead>
        <tbody>
          {statsData.map((item, index) => (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>{item.type}</td>
              <td>{item.duration}</td>
              <td className={item.unclosed_pnl >= 0 ? 'positive' : 'negative'}>{item.unclosed_pnl}</td>
              <td className={item.closed_pnl >= 0 ? 'positive' : 'negative'}>{item.closed_pnl}</td>
              <td className={item.total_pnl >= 0 ? 'positive' : 'negative'}>{item.total_pnl}</td>
              <td>{item.updated_at}</td>
              {/* <td>{item.action}</td> Uncomment this if action field is included */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StateDevelopmentComponent;
